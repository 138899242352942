import React            from 'react';
import PropTypes        from 'prop-types';
import BasicTextInput   from './BasicTextInput';

class TextInput extends React.Component {
	constructor(props) {
		super(props);

		this.cleanInput       = this.cleanInput.bind(this);
		this.getPlainText     = this.getPlainText.bind(this);
		this.getPlainTextFull = this.getPlainTextFull.bind(this);
		this.popIncludedGifts = this.popIncludedGifts.bind(this);
		this.setFocus         = this.setFocus.bind(this);
		this.setValue         = this.setValue.bind(this);
	}

	cleanInput(ignoreFocus) {
		this.editor.cleanInput(ignoreFocus);
	}

	getPlainText(doNotIgnoreEmptyLines) {
		return this.editor.getPlainText(doNotIgnoreEmptyLines);
	}

	getPlainTextFull(callback, ignoreFocus, isReturn) {
		this.editor.getPlainTextFull(callback, ignoreFocus, isReturn);
	}

	popIncludedGifts() {
		return this.editor.popIncludedGifts();
	}

	hasMessage() {
		return this.editor.hasMessage();
	}

	setFocus() {
		this.editor.setFocus();
	}

	setValue(value) {
		this.editor.setValue(value);
	}

	render() {
		return <BasicTextInput
			{...this.props}
			ref={editor => {
				this.editor = editor;
			}}
		/>;
	}
}

TextInput.propTypes = {
	onSendTextMessage:        PropTypes.func,
	onSubmit:                 PropTypes.func,
	textInputPlaceholder:     PropTypes.string,
	value:                    PropTypes.string,
	onChange:                 PropTypes.func,
	readOnly:                 PropTypes.bool,
	maxLength:                PropTypes.number,
	onResize:                 PropTypes.func,
	displayRemainingChars:    PropTypes.bool,
	remainingCharsClassName:  PropTypes.string,
	displayUsedChars:         PropTypes.bool,
	usedCharsClassName:       PropTypes.string,
	editorContainerClassName: PropTypes.string,
	ignoreEmptyLines:         PropTypes.bool,
	onBlur:                   PropTypes.func,
	onFocus:                  PropTypes.func,
	rows:                     PropTypes.number,
	rowsAutoGrowMax:          PropTypes.number,
};

TextInput.defaultProps = {
	ignoreEmptyLines: false,
	rows:             1,
	value:            '',
};

export default TextInput;